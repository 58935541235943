<template>
  <div>
    <div class="mb-0 flex justify-center" style="border-bottom: 1px solid gainsboro">
      <router-link to="/">
        <img src="/melior_dk.svg" class="mb-4 h-8" alt="" />
      </router-link>
    </div>
    <div class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5 col-md-offset-3">
            <div class="row mt-topp">
              <div class="col-lg-12">
                <div class="mb-5">
                  <h4 class="text-dark text-4xl font-weight-bold">Sign in to Account</h4>
                </div>

                <div>
                  <form id="login-form" @submit.prevent="submitForm" role="form">
                    <div class="form-group">
                      <label class="text-dark" to="email">Email address</label>
                      <input v-model="email" required type="email" name="email" tabindex="1" id="email"
                        class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                    </div>
                    <div class="form-group">
                      <label class="text-dark" to="password">Password</label>
                      <input type="password" required v-model="password" name="password" id="password" tabindex="2"
                        class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                    </div>
                    <div class="text-right">
                      <router-link to="/forgot-password" class="text-primary text-underline">
                        Forgot password?
                      </router-link>
                    </div>

                    <div class="form-group text-center mt-5">
                      <button :disabled="loading" type="submit"
                        class="bg-primary text-white py-3 px-4 mr-3 hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full">
                        Login
                        <BtnLoading v-if="loading" class="d-inline-block" height="18" />
                      </button>
                    </div>

                    <!-- <GoogleSigninButton :params="params" :success="onSuccess" :error="onFailure"
                      class="bg-white text-black py-3 px-4 mr-3 border border-gray-600 hover:bg-primary rounded focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full flex items-center space-x-4 justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:v="https://vecta.io/nano" class="h-5"
                        viewBox="0 0 186.69 190.5">
                        <g transform="translate(1184.583 765.171)">
                          <path clip-path="none" mask="none"
                            d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
                            fill="#4285f4" />
                          <path clip-path="none" mask="none"
                            d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
                            fill="#34a853" />
                          <path clip-path="none" mask="none"
                            d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
                            fill="#fbbc05" />
                          <path
                            d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
                            fill="#ea4335" clip-path="none" mask="none" />
                        </g>
                      </svg>
                      <span> Continue with Google</span>
                    </GoogleSigninButton> -->


                    <p class="text-dark mb-5 mt-3">
                      Don't have an account?
                      <router-link to="/create-account">
                        <span class="text-primary text-underline">Sign up</span>
                      </router-link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
import { GoogleSigninButton } from 'vue-google-signin-button'

export default {
  components: { BtnLoading, GoogleSigninButton },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      emailLogin: true,
      formErrors: {
        email: "",
        password: "",
      },
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id:
          "29163087108-lksa9kpc6qo1logo1ci3k4gev9gh904n.apps.googleusercontent.com",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  mounted() {
    if (this.$route.query.uuid) {
      this.checkUserRedirect();
    }
  },
  computed: {
    isAndroid() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android/i.test(userAgent);
    },
    downloadLink() {
      const apple = "https://apps.apple.com/ua/app/melior-music/id1625434097";
      const android =
        "https://play.google.com/store/apps/details?id=com.meliormusic.africa&hl=en&gl=US";

      return this.isAndroid ? android : apple;
    },
  },
  methods: {
    checkUserRedirect() {
      this.$vs.loading();
      const { uuid } = this.$route.query;
      let data = {};
      let payload = {
        data,
        path: `auth/redirect-login?uuid=${uuid}`,
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.$vs.loading.close();
          let { token, user } = resp.data.data;
          token = this.CryptoJS.AES.encrypt(
            token.access_token,
            this.$passPhrase
          ).toString();
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("token", token);
          localStorage.setItem("user", user);
          this.$toast.success(
            "Login",
            "Login successful.",
            this.$toastPosition
          );

          setTimeout(() => {
            window.location.href = "/upgrade-plan";
          }, 1000);
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.formErrors = err.response.data.payload;
            this.$toast.info(
              "Login",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Login",
              "Unable to login, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
    submitForm() {
      this.loading = true;
      let data = {
        email: this.email,
        password: this.password,
      };
      let payload = {
        data,
        path: "/auth/login",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.loading = false;

          let { token, user } = resp.data.data;
          // let user = resp.data.payload.user;
          token = this.CryptoJS.AES.encrypt(
            token.access_token,
            this.$passPhrase
          ).toString();
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("token", token);
          localStorage.setItem("user", user);
          this.$toast.success(
            "Login",
            "Login successful.",
            this.$toastPosition
          );

          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.formErrors = err.response.data.payload;
            this.$toast.info(
              "Login",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Login",
              "Unable to login, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
    onSuccess(data) {
      // console.log('Success', data)
    },
    onFailure(data) {
      // console.log('Failure', data)
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-site-white {
  background-color: white !important;
  color: black;
  box-shadow: 0px 0px;
  border: 1px solid black;
}
</style>
